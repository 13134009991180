import { render, staticRenderFns } from "./DigitalScene.vue?vue&type=template&id=76ac7517&"
import script from "./DigitalScene.vue?vue&type=script&lang=ts&"
export * from "./DigitalScene.vue?vue&type=script&lang=ts&"
import style0 from "./DigitalScene.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AdaptiveImageNew: require('/home/deploy/scenkonst-om-frontend-staging/releases/20240822130500/src/components/media/AdaptiveImageNew.vue').default,Button: require('/home/deploy/scenkonst-om-frontend-staging/releases/20240822130500/src/components/button/Button.vue').default,BlockWrapper: require('/home/deploy/scenkonst-om-frontend-staging/releases/20240822130500/src/components/block/BlockWrapper.vue').default})
